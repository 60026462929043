import React from "react"

import { getField, relatorioDamsLancados, edit } from "../../../util/alvara"
import { get } from "../../../util/taxa"
import { get as getTipoAlvara } from "../../../util/tiposalvara"
import { search as searchContribuinte } from "../../../util/contribuinte"
import { showNotification, showWaiting, hideWaiting, mapErrors } from "../../../util/Constants"
import housecall from "housecall"
import Button from "../../../components/CustomButtons/Button"
import { withStyles, MenuItem } from "@material-ui/core"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import Grid from "@material-ui/core/Grid"
import SearchFields from "../../../components/SearchFields"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import AsyncSelect from "../../../components/AsyncSelect"
import SelectCustom from "../../../components/SelectCustom"
import LoadingContent from "../../../components/LoadingContent"
import InputMask from "react-input-mask"
import FastSelect from "../../../components/FastSelect"



class RelatorioDamsLancados extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contribuintes: [],
            contribuinte_id: "",
            errors: {},
            options: false,
            tipos_alvara: [],
            id_tipos_alvara: "",
            taxas: [],
            id_taxas: "",
            competencia: ""
        }
        this.handleChange = this.handleChange.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.getData = this.getData.bind(this)
        // this.getTiposAlvara = this.getTiposAlvara.bind(this)
        this.getTaxaById = this.getTaxaById.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
        errors[name] = undefined
        if (name === "options") {
            value = event.target.checked
        }

        this.setState(
            {
              [name]: value,
              errors: errors
            },
            () => {
              if (this.state.id_tipos_alvara && name === "id_tipos_alvara") {
                let tipoAlvara = this.getTaxaById(this.state.id_tipos_alvara)
                this.setState({
                  taxas: tipoAlvara.taxas,
                  id_taxas: tipoAlvara.taxas[0].id
                })
              }
            }
          )
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })

                    this.setState({
                        contribuintes: data.data
                    }, () => {

                    })
                    callback(contribuintes)
                }
            }
        )
    }

    componentWillMount() {
        // this.getTiposAlvara()
        this.getData()
    }

    getData() {
        this.toggleLoading()
        let response = (error, data) => {
          this.toggleLoading()
          if (error) {
            showNotification(
              "top",
              "center",
              "Ocorreram erros, verifique sua conexão com a internet",
              "danger"
            )
          } else {
            console.log(data.alvara)
            this.setState({
              tipos_alvara: data.tipos_de_alvara
            })
    
            this.setState({ ...data.alvara })
          }
        }
        getField(response)
      }

    getTaxaById(id) {
        return this.state.tipos_alvara.filter(item => {
          return id === item.id
        })[0]
      }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }

        relatorioDamsLancados(this.state.id_taxas ? this.state.id_taxas : undefined, this.state.contribuinte_id ? this.state.contribuinte_id.value : undefined, this.state.competencia ? this.state.competencia : undefined, this.state.id_tipos_alvara ? this.state.id_tipos_alvara : undefined, response)
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <Card>
                        <CardHeader color="primary">
                            <Grid container direction="row">
                                <Grid item lg={10} xs={12}>
                                    <h2 className={classes.cardTitleWhite}>Relatório de Cadastro de Alvará</h2>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <FastSelect
                                        name="id_tipos_alvara"
                                        label="Tipos de Alvará"
                                        error={this.state.errors.tipo_id}
                                        value={this.state.id_tipos_alvara}
                                        onChange={this.handleChange}
                                        options={
                                            this.state.tipos_alvara.map((option) => (
                                              { value: option.id, label: option.descricao }
                                            ))
                                          }
                                    >
                                    </FastSelect>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <InputMask
                                        mask="9999"
                                        value={this.state.competencia}
                                        onChange={this.handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                label="Competência"
                                                name="competencia"
                                                helperText={this.state.errors.competencia}
                                                fullWidth
                                                error={this.state.errors.competencia}
                                                value={this.state.competencia}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        required
                                        name="id_taxas"
                                        label="Taxas"
                                        fullWidth
                                        value={this.state.id_taxas}
                                        onChange={this.handleChange}
                                        error={this.state.errors.id_taxas}
                                        helperText={this.state.errors.id_taxas}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        <MenuItem key={0} value={""}>
                                            {"Todas as Taxas"}
                                        </MenuItem>
                                        {this.state.taxas.map((option, key) => (
                                        <MenuItem key={key} value={option.id}>
                                            {option.descricao}
                                        </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" style={{ marginTop: "2%" }}>
                                <Divider />
                                <Typography
                                    className={classes.dividerFullWidth}
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Filtro por Contribuinte
                            </Typography>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SearchFields
                                        name="filterField"
                                        value={this.state.filterField || ""}
                                        onChangeFields={this.handleChange}
                                        fields={fields}
                                    />
                                    <AsyncSelect
                                        className={
                                            `form-control form-control-alternative form-select ${
                                            this.props.error ? "has-danger" : ""
                                            }`
                                        }
                                        // disabled={!this.state.options}
                                        value={this.state.contribuinte_id}
                                        onChange={this.handleChange}
                                        noResultsText="Nenhum resultado"
                                        loadOptions={this.loadOptions}
                                        loadingMessage={() => "Carregando..."}
                                        error={this.state.errors.contribuinte_id}
                                        helperText={this.state.errors.contribuinte_id}
                                        name="contribuinte_id"
                                        placeholder="Contribuinte"
                                        message="Pesquise o contribuinte"
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container justify="center">
                                <Grid item lg={2}>
                                    <Button block color="info" round onClick={this.handleSave}>
                                        Gerar
                                </Button>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                </LoadingContent>
            </div>
        )
    }
}

export default withStyles(contribuinteStyle)(RelatorioDamsLancados)